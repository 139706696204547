<template>
  <v-container fluid>
    <v-card tile flat min-height="500">
      <v-card-text>
        <v-row align="center" justify="center">
          <v-col cols="7">
            <v-img
              contain
              src="https://ik.imagekit.io/vconexID/Property_Expo/assets/undraw_dashboard_nklg.svg"
            ></v-img>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {};
</script>

<style></style>
